import React, { useEffect, useState } from "react";
import background from "../../assets/img/pricing-background.jpg";
import { Links } from "../Links/Links";
import pricingStyles from "./Pricing.module.css";
import { Image } from "../Image/Image";
import { Logo } from "../Logo/Logo";
import SpecialOffer_es from "../../assets/img/icons/special-offer_es.svg";
import SpecialOffer_en from "../../assets/img/icons/special-offer_en.svg";
import { useTranslation } from "react-i18next";
import { Footer } from "../Footer/Footer";
import { NavBar } from "../NavBar/NavBar";

export function Pricing(props) {
  const { t, i18n } = useTranslation();
  
  const ImageOffer = i18n.language === 'es' ? SpecialOffer_es : SpecialOffer_en;
  
  const first = {
    title: t("section:pricing:titleFirst"),
    price: 14.95,
    showOffer: false,
  };
  
  const offer = {
    title: t("section:pricing:titleOffer"),
    price: 10.95,
    showOffer: true,
  };

  const [plan, setPlan] = useState(first);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPlan(offer);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <NavBar white no-menu />
      <NavBar fixed no-menu showFrom={600} />
      <div
        style={{ backgroundImage: `url(${background})` }}
        className={pricingStyles.header}
      >
        <div className={pricingStyles.header__bottom}>
          <div className={pricingStyles.header__bottom__left}>
            <h1
              className={
                plan.showOffer ? "animate__animated animate__rubberBand" : ""
              }
            >
              {plan.title}
            </h1>
            <Links.BuyNow />
          </div>
          <div className={pricingStyles.header__bottom__right}>
            {plan.showOffer && (
              <div style={{position: 'absolute', margin: -15}} className="animate__animated animate__rubberBand">
                {t("section:pricing:titleCoupon")} {": "}
                <span className={pricingStyles.header__bottom__right__old}>
                  JSBYNWW
                </span>
              </div>
            )}
            <div
              className={[
                pricingStyles.header__bottom__right__new,
                plan.showOffer ? "animate__animated animate__rubberBand" : "",
              ].join(" ")}
            >
              USD <span>{plan.price}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={pricingStyles.content__main}>
        <div className={pricingStyles.content}>
          {plan.showOffer && (
            <Image
              size="150px"
              img={ImageOffer}
              classes="animate__animated animate__rubberBand"
            />
          )}
          {/* 1 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:1:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:1:text1")}</li>
              <li>{t("section:pricing:items:1:text2")}</li>
              <li>{t("section:pricing:items:1:text3")}</li>
              <li>{t("section:pricing:items:1:text4")}</li>
              <li>{t("section:pricing:items:1:text5")}</li>
            </ul>
          </div>
          {/* 2 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:2:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:2:text1")}</li>
              <li>{t("section:pricing:items:2:text2")}</li>
              <li>{t("section:pricing:items:2:text3")}</li>
              <li>{t("section:pricing:items:2:text4")}</li>
              <li>{t("section:pricing:items:2:text5")}</li>
              <li>{t("section:pricing:items:2:text6")}</li>
              <li>{t("section:pricing:items:2:text7")}</li>
              <li>{t("section:pricing:items:2:text8")}</li>
              <li>{t("section:pricing:items:2:text9")}</li>
            </ul>
          </div>
          {/* 3 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:3:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:3:text1")}</li>
              <li>{t("section:pricing:items:3:text2")}</li>
            </ul>
          </div>
          {/* 4 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:4:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:4:text1")}</li>
              <li>{t("section:pricing:items:4:text2")}</li>
              <li>{t("section:pricing:items:4:text3")}</li>
              <li>{t("section:pricing:items:4:text4")}</li>
              <li>{t("section:pricing:items:4:text5")}</li>
              <li>{t("section:pricing:items:4:text6")}</li>
              <li>{t("section:pricing:items:4:text7")}</li>
              <li>{t("section:pricing:items:4:text8")}</li>
              <li>{t("section:pricing:items:4:text9")}</li>
              <li>{t("section:pricing:items:4:text10")}</li>
              <li>{t("section:pricing:items:4:text11")}</li>
              <li>{t("section:pricing:items:4:text12")}</li>
              <li>{t("section:pricing:items:4:text13")}</li>
            </ul>
          </div>
          {/* 5 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:5:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:5:text1")}</li>
              <li>{t("section:pricing:items:5:text2")}</li>
              <li>{t("section:pricing:items:5:text3")}</li>
              <li>{t("section:pricing:items:5:text4")}</li>
            </ul>
          </div>
          {/*6 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:6:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:6:text1")}</li>
              <li>{t("section:pricing:items:6:text2")}</li>
              <li>{t("section:pricing:items:6:text3")}</li>
              <li>{t("section:pricing:items:6:text4")}</li>
              <li>{t("section:pricing:items:6:text5")}</li>
              <li>{t("section:pricing:items:6:text6")}</li>
            </ul>
          </div>
          {/*7 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:7:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:7:text1")}</li>
              <li>{t("section:pricing:items:7:text2")}</li>
            </ul>
          </div>
          {/*8 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:8:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:8:text1")}</li>
              <li>{t("section:pricing:items:8:text2")}</li>
              <li>{t("section:pricing:items:8:text3")}</li>
            </ul>
          </div>
          {/*9 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:9:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:9:text1")}</li>
              <li>{t("section:pricing:items:9:text2")}</li>
              <li>{t("section:pricing:items:9:text3")}</li>
              <li>{t("section:pricing:items:9:text4")}</li>
            </ul>
          </div>
          {/*10 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:10:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:10:text1")}</li>
            </ul>
          </div>
          {/*11 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:11:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:11:text1")}</li>
              <li>{t("section:pricing:items:11:text2")}</li>
              <li>{t("section:pricing:items:11:text3")}</li>
              <li>{t("section:pricing:items:11:text4")}</li>
              <li>{t("section:pricing:items:11:text5")}</li>
            </ul>
          </div>
          {/*12 */}
          <div className={pricingStyles.content__item}>
            <h3 className={pricingStyles.content__item__title}>
              {t("section:pricing:items:12:title")}
            </h3>
            <ul>
              <li>{t("section:pricing:items:12:text1")}</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer footer-style="blue" />
    </>
  );
}
