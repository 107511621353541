import React from 'react';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { Tabs } from '../../Tabs/Tabs';

import firstIconSVG from '../../../assets/img/icons/benefits/1.svg';
import firstIconSelectedSVG from '../../../assets/img/icons/benefits/1.selected.svg';
import secondIconSVG from '../../../assets/img/icons/benefits/2.svg';
import secondIconSelectedSVG from '../../../assets/img/icons/benefits/2.selected.svg';
import thirdIconSVG from '../../../assets/img/icons/benefits/3.svg';
import thirdIconSelectedSVG from '../../../assets/img/icons/benefits/3.selected.svg';
import fourthIconSVG from '../../../assets/img/icons/benefits/4.svg';
import fourthIconSelectedSVG from '../../../assets/img/icons/benefits/4.selected.svg';
import fifthIconSVG from '../../../assets/img/icons/benefits/5.svg';
import fifthIconSelectedSVG from '../../../assets/img/icons/benefits/5.selected.svg';
import { useTranslation } from 'react-i18next';
import { SelectableItem } from '../../SelectableItem/SelectableItem';
import { TabContent } from '../../TabContent/TabContent';
import background from '../../../assets/img/benefits-background.png';
import benefitsStyles from './Benefits.module.css';

import firstImage from '../../../assets/img/benefits/1.png';
import secondImage from '../../../assets/img/benefits/2.png';
import thirdImage from '../../../assets/img/benefits/3.png';
import fourthImage from '../../../assets/img/benefits/4.png';
import fifthImage from '../../../assets/img/benefits/5.png';

export function Benefits(props) {
  const [t] = useTranslation();
  return (
    <section
      className={[benefitsStyles.back, benefitsStyles.benefits].join(' ')}
      style={{ backgroundColor: '#FFF', backgroundSize: 'cover' }}
      id='benefits'
    >
      <SectionHeader benefits title='section:benefits:title' subtitle='section:benefits:subtitle'></SectionHeader>
      <div>
        <Tabs>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='yellow'
                  selected={selected}
                  onSelectedIcon={firstIconSelectedSVG}
                  icon={firstIconSVG}
                  label={t('section:benefits:tabs:1:header-item')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={firstIconSelectedSVG}
                title={t('section:benefits:tabs:1:title')}
                text={t('section:benefits:tabs:1:content')}
                img={firstImage}
                colorStyle='yellow'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='yellow'
                  selected={selected}
                  onSelectedIcon={secondIconSelectedSVG}
                  icon={secondIconSVG}
                  label={t('section:benefits:tabs:2:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={secondIconSelectedSVG}
                title={t('section:benefits:tabs:2:title')}
                text={t('section:benefits:tabs:2:content')}
                img={secondImage}
                colorStyle='yellow'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='yellow'
                  selected={selected}
                  onSelectedIcon={thirdIconSelectedSVG}
                  icon={thirdIconSVG}
                  label={t('section:benefits:tabs:3:header-item')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={thirdIconSelectedSVG}
                title={t('section:benefits:tabs:3:title')}
                text={t('section:benefits:tabs:3:content')}
                img={thirdImage}
                colorStyle='yellow'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='yellow'
                  selected={selected}
                  onSelectedIcon={fourthIconSelectedSVG}
                  icon={fourthIconSVG}
                  label={t('section:benefits:tabs:4:header-item')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={fourthIconSelectedSVG}
                title={t('section:benefits:tabs:4:title')}
                text={t('section:benefits:tabs:4:content')}
                img={fourthImage}
                colorStyle='yellow'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='yellow'
                  selected={selected}
                  onSelectedIcon={fifthIconSelectedSVG}
                  icon={fifthIconSVG}
                  label={t('section:benefits:tabs:5:header-item')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={fifthIconSelectedSVG}
                title={t('section:benefits:tabs:5:title')}
                text={t('section:benefits:tabs:5:content')}
                img={fifthImage}
                colorStyle='yellow'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
        </Tabs>
      </div>
    </section>
  );
}
