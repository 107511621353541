import React from 'react';
import background from '../../../assets/img/welcome-background.jpg';
import background2 from '../../../assets/img/welcome-background2.jpg';
import { Icon } from '../../Icon/Icon';
import { Links } from '../../Links/Links';
import { NavBar } from '../../NavBar/NavBar';
import welcomeStyles from './Welcome.module.css';
import TickIcon from '../../../assets/img/icons/tick.svg';
import { useTranslation } from 'react-i18next';
import 'animate.css';

export function Welcome() {
  const { t } = useTranslation();
  return (
    <div className={welcomeStyles.welcome}>
      <div style={{ backgroundImage: `url(${background})` }} className={[welcomeStyles.welcome__slider]}></div>
      <div style={{ backgroundImage: `url(${background2})` }} className={[welcomeStyles.welcome__slider]}></div>
      <NavBar only-hash-nav />
      <div className={welcomeStyles.welcome__content}>
        <h1 className='animate__animated animate__lightSpeedInLeft'>
        <span className={welcomeStyles.welcome__highlighted}>{t('section:welcome:title1')}</span> {t('section:welcome:title2')}<span className={welcomeStyles.welcome__highlighted}> {t('section:welcome:title3')}</span>
        </h1>
        <Icon.InlineGroup>
          <Icon img={TickIcon} label={t('icon:precision')} classes={welcomeStyles.welcome__icon} />
          <Icon img={TickIcon} label={t('icon:tracking')} classes={welcomeStyles.welcome__icon} />
          <Icon img={TickIcon} label={t('icon:state')} classes={welcomeStyles.welcome__icon} />
        </Icon.InlineGroup>
        <div className={welcomeStyles.welcome__cta}>
          <Links.GetPricing></Links.GetPricing>
        </div>
      </div>
    </div>
  );
}
