import React from 'react';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { Tabs } from '../../Tabs/Tabs';
import background from '../../../assets/img/characteristics-background.png';

import firstIconSVG from '../../../assets/img/icons/features/1.svg';
import firstIconSelectedSVG from '../../../assets/img/icons/features/1.selected.svg';
import secondIconSVG from '../../../assets/img/icons/features/2.svg';
import secondIconSelectedSVG from '../../../assets/img/icons/features/2.selected.svg';
import thirdIconSVG from '../../../assets/img/icons/features/3.svg';
import thirdIconSelectedSVG from '../../../assets/img/icons/features/3.selected.svg';
import fourthIconSVG from '../../../assets/img/icons/features/4.svg';
import fourthIconSelectedSVG from '../../../assets/img/icons/features/4.selected.svg';
import fifthIconSVG from '../../../assets/img/icons/features/5.svg';
import fifthIconSelectedSVG from '../../../assets/img/icons/features/5.selected.svg';
import { useTranslation } from 'react-i18next';
import { SelectableItem } from '../../SelectableItem/SelectableItem';
import { TabContent } from '../../TabContent/TabContent';
import characteristicsStyles from './Characteristics.module.css';

import firstImage from '../../../assets/img/characteristics/1.png';
import secondImage from '../../../assets/img/characteristics/2.png';
import thirdImage from '../../../assets/img/characteristics/3.png';
import fourthImage from '../../../assets/img/characteristics/4.png';
import fifthImage from '../../../assets/img/characteristics/5.png';

export function Characteristics(props) {
  const [t] = useTranslation();
  return (
    <section
      className={[characteristicsStyles.back, characteristicsStyles.characteristics].join(' ')}
      style={{ backgroundSize: 'cover', backgroundColor: '#FFF' }}
      id='characteristics'
    >
      <SectionHeader features title='section:characteristics:title' subtitle='section:characteristics:subtitle'></SectionHeader>
      <div>
        <Tabs>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='lightblue'
                  selected={selected}
                  onSelectedIcon={firstIconSelectedSVG}
                  icon={firstIconSVG}
                  label={t('section:characteristics:tabs:1:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={firstIconSelectedSVG}
                title={t('section:characteristics:tabs:1:title')}
                text={t('section:characteristics:tabs:1:content')}
                img={firstImage}
                colorStyle='lightblue'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='lightblue'
                  selected={selected}
                  onSelectedIcon={secondIconSelectedSVG}
                  icon={secondIconSVG}
                  label={t('section:characteristics:tabs:2:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={secondIconSelectedSVG}
                title={t('section:characteristics:tabs:2:title')}
                text={t('section:characteristics:tabs:2:content')}
                img={secondImage}
                colorStyle='lightblue'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='lightblue'
                  selected={selected}
                  onSelectedIcon={thirdIconSelectedSVG}
                  icon={thirdIconSVG}
                  label={t('section:characteristics:tabs:3:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={thirdIconSelectedSVG}
                title={t('section:characteristics:tabs:3:title')}
                text={t('section:characteristics:tabs:3:content')}
                img={thirdImage}
                colorStyle='lightblue'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='lightblue'
                  selected={selected}
                  onSelectedIcon={fourthIconSelectedSVG}
                  icon={fourthIconSVG}
                  label={t('section:characteristics:tabs:4:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={fourthIconSelectedSVG}
                title={t('section:characteristics:tabs:4:title')}
                text={t('section:characteristics:tabs:4:content')}
                img={fourthImage}
                colorStyle='lightblue'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='lightblue'
                  selected={selected}
                  onSelectedIcon={fifthIconSelectedSVG}
                  icon={fifthIconSVG}
                  label={t('section:characteristics:tabs:5:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={fifthIconSelectedSVG}
                title={t('section:characteristics:tabs:5:title')}
                text={t('section:characteristics:tabs:5:content')}
                img={fifthImage}
                colorStyle='lightblue'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
        </Tabs>
      </div>
    </section>
  );
}
