import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import background from "../../assets/img/pricing-background.jpg";
import { Links } from "../Links/Links";
import contactStyles from "./Contact.module.css";
import { useTranslation } from "react-i18next";
import { Footer } from "../Footer/Footer";
import { NavBar } from "../NavBar/NavBar";
import { Input } from "../Input/Input";
import { Logo } from "../Logo/Logo";
import { Icon } from "../Icon/Icon";
import phoneIcon from "../../assets/img/icons/phone_white.svg";

export function Contact(props) {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, errors } = useForm();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  async function postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  const onSubmit = async (data) => {
    setError(false);
    setSuccess(false);
    await postData("http://e-track.us/api/form.php", {
      ...data,
      form: "general",
    })
      .then((res) => {
        setSuccess(true);
        reset();
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setTimeout(() => {
          setError(false);
          setSuccess(false);
        }, 3000);
      });
  };

  return (
    <>
      <NavBar white no-menu />
      <NavBar fixed no-menu showFrom={600} />

      <div
        style={{ backgroundImage: `url(${background})` }}
        className={contactStyles.contact__wrapper}
      >
        <div className={contactStyles.contact__row}>
          <div className={contactStyles.contact__column}>
            <div className={contactStyles.contact__logo_column}>
              <Logo logoStyle="white" height="100%" />
              <Icon
                img={phoneIcon}
                label="(844) 763-2173"
                classes={contactStyles.icon}
              />
            </div>
          </div>
          <div className={contactStyles.contact__column}>
            <div className={contactStyles.content__main}>
              <div className={contactStyles.content}>
                <div className={contactStyles.content__title}>
                  <h3 className={contactStyles.content__title_head}>
                    {t("section:contact:form:title")}
                  </h3>
                  <h3 className={contactStyles.content__title_sub}>
                    {t("section:contact:form:subtitle")}
                  </h3>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    name="firstName"
                    label={t("section:contact:form:firstname")}
                    ref={register({ required: true })}
                    error={errors.firstName}
                    maxlength={15}
                    type="text"
                  />
                  <Input
                    name="lastName"
                    label={t("section:contact:form:lastname")}
                    ref={register({ required: true })}
                    error={errors.lastName}
                    maxlength={15}
                    type="text"
                  />
                  <Input
                    name="email"
                    label={t("section:contact:form:email")}
                    ref={register({ required: true })}
                    error={errors.email}
                    maxlength={30}
                    type="email"
                  />
                  <Input
                    name="phone"
                    label={t("section:contact:form:phone")}
                    ref={register({ required: true })}
                    error={errors.phone}
                    maxlength={15}
                    type="number"
                  />
                  <Input
                    name="company"
                    label={t("section:contact:form:company")}
                    ref={register({ required: true })}
                    error={errors.company}
                    maxlength={30}
                    type="text"
                  />
                  <Input
                    name="devices"
                    label={t("section:contact:form:devices")}
                    ref={register({ required: true })}
                    error={errors.devices}
                    maxlength={15}
                    type="number"
                  />

                  <Input
                    name="code"
                    label={t("section:contact:form:coupon")}
                    ref={register}
                    maxlength={10}
                    type="text"
                  />
                  {success && (
                    <span className={contactStyles.success}>
                      Mensaje enviado con éxito
                    </span>
                  )}
                  {error && (
                    <span className={contactStyles.error}>
                      Error al enviar el mensaje
                    </span>
                  )}
                  <div className={contactStyles.container__btn}>
                    
                    <div className={contactStyles.btn}>
                      <div className={contactStyles.btn_send}>
                        <Links.SendContact />
                      </div>
                    </div>

                    <span className={contactStyles.terms}>
                      {t("section:contact:terms")} <b>eTrack</b>
                    </span>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer footer-style="blue" />
    </>
  );
}
