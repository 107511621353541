import React from 'react';
import { Icon } from '../Icon/Icon';
import classnames from 'classnames';
import selectableItemStyles from './SelectableItem.module.css';

export function SelectableItem(props) {
  return (
    <div
      className={classnames(
        selectableItemStyles['selectable-item'],
        selectableItemStyles[`selectable-item--${props['item-style']}`],
        {
          [selectableItemStyles['selectable-item--selected']]: props.selected,
        }
      )}
    >
      <div style={{ display: props.selected ? 'none' : 'block' }}>
        <Icon
          img={props.icon}
          classes={[
            selectableItemStyles['selectable-item__icon'],
            selectableItemStyles[`selectable-item__icon--${props['item-style']}`],
          ].join(' ')}
          label={props.label}
        />
      </div>
      <div style={{ display: props.selected ? 'block' : 'none' }}>
        <Icon
          img={props.onSelectedIcon}
          classes={[
            selectableItemStyles['selectable-item__icon'],
            selectableItemStyles[`selectable-item__icon--${props['item-style']}`],
          ].join(' ')}
          label={props.label}
        />
      </div>
    </div>
  );
}
