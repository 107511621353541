import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import customerLoginStyles from './CustomerLogin.module.css';

export function CustomerLogin() {
  const { t } = useTranslation();
  return (
    <button className={customerLoginStyles.button}>
      <a target="_blank" href='https://gps.e-track.us/'>{t('buttons:login')}</a>
    </button>
  );
}
