import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import getPricingStyles from "./GetPricing.module.css";

export function GetPricing() {
  const { t } = useTranslation();
  return (
    <Link to="/pricing" className={getPricingStyles.linkButton}>
      <button className={getPricingStyles.button}>
        <span>{t("buttons:pricing")}</span>
      </button>
    </Link>
  );
}
