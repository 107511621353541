import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../Dropdown/Dropdown';
import { Icon } from '../Icon/Icon';
import USFlag from '../../assets/img/icons/USFlag.svg';
import ESFlag from '../../assets/img/icons/ESFlag.svg';
import languageSelectorStyles from './LanguageSelector.module.css';
import classnames from 'classnames';

export function LanguageSelector(props) {
  const { t, i18n } = useTranslation();
  const asDropdown = !('no-dropdown' in props);
  return asDropdown ? (
    <Dropdown onSelectionChange={(lng) => i18n.changeLanguage(lng)} selected={i18n.language}>
      <Dropdown.Item value={'en'}>
        <Icon img={USFlag} label={t('languages:english')} classes={languageSelectorStyles['language-dropdown__icon']} />
      </Dropdown.Item>
      <Dropdown.Item value={'es'}>
        <Icon img={ESFlag} label={t('languages:spanish')} classes={languageSelectorStyles['language-dropdown__icon']} />
      </Dropdown.Item>
    </Dropdown>
  ) : (
    <>
      <div
        className={languageSelectorStyles['language-dropdown__item--no-dropdown']}
        onClick={() => i18n.changeLanguage('en')}
      >
        <Icon
          img={USFlag}
          label={t('languages:english')}
          classes={classnames(languageSelectorStyles['language-dropdown__icon'], {
            [languageSelectorStyles['language-dropdown__icon--selected']]: i18n.language === 'en',
          })}
        />
      </div>
      <div
        className={languageSelectorStyles['language-dropdown__item--no-dropdown']}
        onClick={() => i18n.changeLanguage('es')}
      >
        <Icon
          img={ESFlag}
          label={t('languages:spanish')}
          classes={classnames(languageSelectorStyles['language-dropdown__icon'], {
            [languageSelectorStyles['language-dropdown__icon--selected']]: i18n.language === 'es',
          })}
        />
      </div>
    </>
  );
}
