import React from 'react';
import imageStyles from './Image.module.css';

export class Image extends React.Component {

  render() {
    const size = this.props.size ?? '20px';
    return (
      <div className={[imageStyles.icon, this.props.classes].join(' ')}>
        <img src={this.props.img} alt={this.props.alt} style={{ width: size, height: size, objectFit: 'contain' }} />
        {this.props.label ? <span className={imageStyles.label}>{this.props.label}</span> : ''}
      </div>
    );
  }
}
