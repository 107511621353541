import React from 'react';
import iconStyles from './Icon.module.css';
import { InlineGroup } from './InlineGroup/InlineGroup';

export class Icon extends React.Component {
  static InlineGroup = InlineGroup;

  render() {
    const size = this.props.size ?? '20px';
    return (
      <div className={[iconStyles.icon, this.props.classes].join(' ')}>
        <img src={this.props.img} alt={this.props.alt} style={{ width: size, height: size, objectFit: 'contain' }} />
        {this.props.label ? <span className={iconStyles.label}>{this.props.label}</span> : ''}
      </div>
    );
  }
}
