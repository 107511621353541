import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import classnames from "classnames";
import menuStyles from "./Menu.module.css";
import { useTranslation } from "react-i18next";

import hamburguerIcon from "../../assets/img/icons/hamburguer.svg";
import hamburguerBlackIcon from "../../assets/img/icons/hamburguer.black.svg";
import closeIcon from "../../assets/img/icons/close.svg";
import { Links } from "../Links/Links";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";
// import { Link } from 'react-router-dom';

export function Menu(props) {
  const [open, setOpen] = useState(false);
  const onlyHash = "only-hash-nav" in props && props["only-hash-nav"];
  const { t, i18n } = useTranslation();

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -60;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div
      className={classnames(menuStyles["menu"], {
        [menuStyles["menu--dark"]]: props.useBlack,
        [menuStyles["menu--open"]]: open,
      })}
      onClick={() => setOpen(false)}
    >
      <button
        className={menuStyles["menu__toggler"]}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <img
          src={props.useBlack ? hamburguerBlackIcon : hamburguerIcon}
          alt=""
        ></img>
      </button>
      <nav
        className={classnames({ [menuStyles["menu--dark"]]: props.useBlack })}
      >
        <button
          className={menuStyles["menu__toggler"]}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
        >
          <img src={closeIcon} alt=""></img>
        </button>
        <Link
          smooth
          to={`${!onlyHash ? "/home" : ""}#product`}
          scroll={(el) => scrollWithOffset(el)}
        >
          {t("menu:product")}
        </Link>
        <Link smooth to={`${!onlyHash ? "/home" : ""}#characteristics`} scroll={(el) => scrollWithOffset(el)}>
          {t("menu:features")}
        </Link>
        <Link smooth to={`${!onlyHash ? "/home" : ""}#benefits`} scroll={(el) => scrollWithOffset(el)}>
          {t("menu:benefits")}
        </Link>
        <Link smooth to={`${!onlyHash ? "/home" : ""}#company`} scroll={(el) => scrollWithOffset(el)}>
          {t("menu:company")}
        </Link>
        <div className={menuStyles["menu__extra-links"]}>
          <Links.GetPricing />
          <Links.CustomerLogin />
        </div>
        <div className={menuStyles["menu__languages"]}>
          <LanguageSelector no-dropdown />
        </div>
      </nav>
    </div>
  );
}
