import React from "react";
import { Links } from "../Links/Links";
import footerStyles from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { Icon } from "../Icon/Icon";
import locationPinIcon from "../../assets/img/icons/location-pin.svg";

export function Footer(props) {
  const { t } = useTranslation();
  const footerStyle = props["footer-style"] ?? "yellow";
  return (
    <footer
      className={classnames(
        footerStyles.footer,
        footerStyles[`footer--${footerStyle}`]
      )}
    >
      <div className={footerStyles["footer__extra-links"]}>
        <Links.Social></Links.Social>
        <Icon
          img={locationPinIcon}
          label="6355 NW 36Th St #409, Virginia Gardens, FL"
          classes={footerStyles["footer__extra-links__extras_icon"]}
        />

        <div className={footerStyles["footer__extra-links__extras"]}>
          <Link
            to="/privacy"
            className={footerStyles["footer__extra-links__extras__privacy"]}
          >
            {t("footer:privacy")}
          </Link>
          <Link
            to="/terms"
            className={footerStyles["footer__extra-links__extras__terms"]}
          >
            {t("footer:terms")}
          </Link>
        </div>

        <p className={footerStyles["footer__extra-links__rights"]}>
          {t("footer:rights")}
        </p>

      </div>
    </footer>
  );
}
