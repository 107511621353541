import React from 'react';
import termsStyles from './Terms.module.css';
import { NavBar } from '../NavBar/NavBar';
import { useTranslation } from 'react-i18next';
import { Footer } from '../Footer/Footer';

export function Terms(props) {
  const { t } = useTranslation();
  return (
    <>
      <NavBar white no-menu />
      <NavBar fixed no-menu showFrom={600} />
      <div className={termsStyles.terms}>
        <div>
          <h1>{t('section:terms:1:title')}</h1>
          <p>{t('section:terms:1:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:2:title')}</h1>
          <p>{t('section:terms:2:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:3:title')}</h1>
          <p>{t('section:terms:3:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:4:title')}</h1>
          <p>{t('section:terms:4:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:5:title')}</h1>
          <p>{t('section:terms:5:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:6:title')}</h1>
          <p>{t('section:terms:6:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:7:title')}</h1>
          <p>{t('section:terms:7:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:8:title')}</h1>
          <p>{t('section:terms:8:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:9:title')}</h1>
          <p>{t('section:terms:9:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:10:title')}</h1>
          <p>{t('section:terms:10:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:11:title')}</h1>
          <p>{t('section:terms:11:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:12:title')}</h1>
          <p>{t('section:terms:12:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:13:title')}</h1>
          <p>{t('section:terms:13:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:14:title')}</h1>
          <p>{t('section:terms:14:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:15:title')}</h1>
          <p>{t('section:terms:15:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:16:title')}</h1>
          <p>{t('section:terms:16:text')}</p>
        </div>
        <div>
          <h1>{t('section:terms:17:title')}</h1>
          <p>{t('section:terms:17:text')}</p>
        </div>
      </div>
      <Footer />
    </>
  );
}
