import React from 'react';
import { withTranslation } from 'react-i18next';
import companyBenefitStyles from './CompanyBenefit.module.css';
import TickIcon from '../../assets/img/icons/tick.svg';
import { Icon } from '../Icon/Icon';
import { CompanyBenefitGroup } from './CompanyBenefitGroup/CompanyBenefitGroup';

class CompanyBenefit extends React.Component {
  static Group = CompanyBenefitGroup;

  render() {
    return (
      <div data-aos="fade" data-aos-duration="500" className={companyBenefitStyles['company-benefit']}>
        <Icon img={TickIcon} classes={companyBenefitStyles['company-benefit__icon']} />
        <p>{this.props.t(this.props.label)}</p>
      </div>
    );
  }
}

const Extended = withTranslation()(CompanyBenefit);
Extended.Group = CompanyBenefit.Group;

export default Extended;
