import React from 'react';
import { useTranslation } from 'react-i18next';
import sectionHeaderStyles from './SectionHeader.module.css';
import classnames from 'classnames';

export function SectionHeader(props) {
  const company = 'company' in props;
  const benefits = 'benefits' in props;
  const features = 'features' in props;
  const product = 'product' in props;

  const { t } = useTranslation();
  return (
    <header data-aos="fade-right" data-aos-duration="500"
    className={classnames(sectionHeaderStyles.header, {
      [sectionHeaderStyles['header--company']]: company,
      [sectionHeaderStyles['header--benefits']]: benefits,
      [sectionHeaderStyles['header--features']]: features,
      [sectionHeaderStyles['header--product']]: product,
    })}>
      <h3>{t(props.title)}</h3>
      <p>{t(props.subtitle)}</p>
    </header>
  );
}
