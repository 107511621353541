import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "react-dom";
import "react-router-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./assets/locales/en/page.json";
import es from "./assets/locales/es/page.json";

import { Pricing } from "./components/Pricing/Pricing";
import { Contact } from "./components/Contact/Contact";
import { Terms } from "./components/Terms/Terms";
import { Privacy } from "./components/Privacy/Privacy";
import { Home } from "./components/Home/Home";
import { initGA } from "./components/GA";

import ScrollToTop from "./utils/ScrollToTop"

const resources = {
  en,
  es,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    keySeparator: true, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

function App() {
  useEffect(() => {
    Aos.init({});
    initGA();
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s.cliengo.com/weboptimizer/58920347e4b02619ac8f5f9a/5f90e8024939f8002a5ef038.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/privacy" exact component={Privacy}></Route>
          <Route path="/pricing" exact component={Pricing}></Route>
          <Route path="/contact" exact component={Contact}></Route>
          <Route path="/terms" exact component={Terms}></Route>
          <Redirect to="/" />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
