import React from 'react';
import { Icon } from '../../Icon/Icon';
import tabContentHeaderStyles from './TabContentHeader.module.css';

export function TabContentHeader(props) {
  return (
    <div
      className={[
        tabContentHeaderStyles['tab-content-header'],
        tabContentHeaderStyles[`tab-content-header--${props['content-style']}`],
      ].join(' ')}
    >
      <Icon
        img={props.icon}
        classes={tabContentHeaderStyles['tab-content-header__icon']}
        size='25px'
        label={props.label}
      />
    </div>
  );
}
