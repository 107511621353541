import React from "react";
import { Icon } from "../Icon/Icon";
import topbarStyles from "./Topbar.module.css";
import emailIcon from "../../assets/img/icons/email.svg";
import locationPinIcon from "../../assets/img/icons/location-pin.svg";
import phoneIcon from "../../assets/img/icons/phone.svg";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";

export function Topbar() {
  return (
    <div className={topbarStyles.topbar}>
      {/* <Icon.InlineGroup> */}
      <div className={topbarStyles.topbarIconContainer}>
        <Icon
          img={phoneIcon}
          label="(844) 763-2173"
          classes={topbarStyles.item}
        />
        <Icon
          img={emailIcon}
          label="contact@e-track.us"
          classes={topbarStyles.item}
        />

        <Icon
          img={locationPinIcon}
          label="6355 NW 36Th St #409, Virginia Gardens, FL"
          classes={[topbarStyles.item, topbarStyles.invisible].join(" ")}
        />
      </div>

      {/* </Icon.InlineGroup> */}
      <div className={topbarStyles.invisible}>
        <LanguageSelector />
      </div>
    </div>
  );
}
