import { Company } from './Company/Company';
import { Product } from './Product/Product';
import { Welcome } from './Welcome/Welcome';
import { Benefits } from './Benefits/Benefits';
import { Characteristics } from './Characteristics/Characteristics';

export class Sections {
  static Welcome = Welcome;
  static Company = Company;
  static Product = Product;
  static Benefits = Benefits;
  static Characteristics = Characteristics;
}
