import React from 'react';
import companyStyles from './Company.module.css';
import background from '../../../assets/img/company-background.png';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import CompanyBenefit from '../../CompanyBenefit/CompanyBenefit';

export function Company() {
  return (
    <section className={companyStyles.company} style={{ backgroundColor: '#53AADD' }} id='company'>
      <SectionHeader company title='section:company:title' subtitle='section:company:subtitle'></SectionHeader>
      <CompanyBenefit.Group>
        <CompanyBenefit label='section:company:benefits:1' />
        <CompanyBenefit label='section:company:benefits:2' />
        <CompanyBenefit label='section:company:benefits:3' />
        <CompanyBenefit label='section:company:benefits:4' />
      </CompanyBenefit.Group>
    </section>
  );
}
