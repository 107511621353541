import React from 'react';
import { extractTabItem, TabItem } from './TabItem/TabItem';
import { TabItemContent } from './TabItem/TabItemContent/TabItemContent';
import { TabItemHeader } from './TabItem/TabItemHeader/TabItemHeader';
import { TabsContent } from './TabsContent/TabsContent';
import { TabsHeader } from './TabsHeader/TabsHeader';
import tabsStyles from './Tabs.module.css';

export class Tabs extends React.Component {
  static TabItem = TabItem;
  static TabItemHeader = TabItemHeader;
  static TabItemContent = TabItemContent;
  state = { selectedIdx: 0 };

  selectTab(idx) {
    this.setState({ selectedIdx: idx });
  }

  render() {
    const headers = [];
    let content;
    React.Children.map(this.props.children, (tab, idx) => {
      const [tabHeader, tabContent] = extractTabItem(tab);
      headers.push(tabHeader);
      if (idx === this.state.selectedIdx) {
        content = tabContent;
      }
    });

    return (
      <div className={tabsStyles['tabs-container']}>
        <TabsHeader selectedIdx={this.state.selectedIdx} onSelectionChange={(idx) => this.selectTab(idx)}>
          {headers}
        </TabsHeader>
        <TabsContent selectedIdx={this.state.selectedIdx}>
          {React.cloneElement(content, { isSelected: true })}
        </TabsContent>
      </div>
    );
  }
}
