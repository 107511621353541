import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import sendContactStyles from "./SendContact.module.css";

export function SendContact() {
  const { t } = useTranslation();
  return (
      <button className={sendContactStyles.button} type="submit">
        <span>{t("buttons:sendContact")}</span>
      </button>
  );
}
