import React from 'react';
import logoSVG from '../../assets/img/logo/etrack.svg';
import logoBlackSVG from '../../assets/img/logo/etrack-black.svg';
import logoWhiteSVG from '../../assets/img/logo/etrack-white.svg';
import logoStyles from './Logo.module.css';
import { Link } from 'react-router-dom';

const LOGO_STYLE_MAPPER = {
  default: logoSVG,
  black: logoBlackSVG,
  white: logoWhiteSVG,
};

export function Logo(props) {
  const logo = LOGO_STYLE_MAPPER[props.logoStyle ?? 'default'];

  return (
    <Link to='/' style={{ height: props.height , display: 'block' }} className={logoStyles.logo}>
      <img src={logo} alt='E-Track logo' />
    </Link>
  );
}
