import React from 'react';
import privacyStyles from './Privacy.module.css';
import { NavBar } from '../NavBar/NavBar';
import { useTranslation } from 'react-i18next';
import { Footer } from '../Footer/Footer';

export function Privacy(props) {
  const { t } = useTranslation();
  return (
    <>
      <NavBar white no-menu />
      <NavBar fixed no-menu showFrom={600} />
      <div className={privacyStyles.privacy}>
        <div>
          <h1>{t('section:privacy:1:title')}</h1>
          <p>{t('section:privacy:1:text')}</p>
        </div>
        <div>
          <h1>{t('section:privacy:2:title')}</h1>
          <p>{t('section:privacy:2:text')}</p>
        </div>
        <div>
          <h1>{t('section:privacy:3:title')}</h1>
          <p>{t('section:privacy:3:text')}</p>
        </div>
        <div>
          <h1>{t('section:privacy:4:title')}</h1>
          <p>{t('section:privacy:4:text')}</p>
        </div>
        <div>
          <h1>{t('section:privacy:5:title')}</h1>
          <p>{t('section:privacy:5:text')}</p>
        </div>
        <div>
          <h1>{t('section:privacy:6:title')}</h1>
          <p>{t('section:privacy:6:text')}</p>
        </div>
        <div>
          <h1>{t('section:privacy:7:title')}</h1>
          <p>{t('section:privacy:7:text')}</p>
        </div>
        <div>
          <h1>{t('section:privacy:8:title')}</h1>
          <p>{t('section:privacy:8:text')}</p>
        </div>
        <div>
          <h1>{t('section:privacy:9:title')}</h1>
          <p>{t('section:privacy:9:text')}</p>
        </div>
        <div>
          <h1>{t('section:privacy:10:title')}</h1>
          <p>{t('section:privacy:10:text')}</p>
        </div>
        <div>
          <h1>{t('section:privacy:11:title')}</h1>
          <p>{t('section:privacy:11:text')}</p>
        </div>
      </div>
      <Footer />
    </>
  );
}
