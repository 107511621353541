import React from 'react';
import { Footer } from '../Footer/Footer';
import { NavBar } from '../NavBar/NavBar';
import { Sections } from '../Sections/Sections';
import { Topbar } from '../Topbar/Topbar';

export function Home(props) {
  return (
    <>
      <Topbar />
      <NavBar only-hash-nav fixed showFrom={600} />
      <Sections.Welcome />
      <Sections.Product />
      <Sections.Characteristics />
      <Sections.Benefits />
      <Sections.Company />
      <Footer footer-style='blue' />
    </>
  );
}
