import { CustomerLogin } from './CustomerLogin/CustomerLogin';
import { GetPricing } from './GetPricing/GetPricing';
import { Social } from './Social/Social';
import { BuyNow } from './BuyNow/BuyNow';
import { SendContact } from './SendContact/SendContact';

export class Links {
  static GetPricing = GetPricing;
  static CustomerLogin = CustomerLogin;
  static Social = Social;
  static BuyNow = BuyNow;
  static SendContact = SendContact;
}
