import React from 'react';
import { TabItemContent } from './TabItemContent/TabItemContent';
import { TabItemHeader } from './TabItemHeader/TabItemHeader';

export function extractTabItem(tab) {
  let header, content;
  React.Children.map(tab.props.children, (child) => {
    if (child.type === TabItemHeader) {
      header = child;
    } else if (child.type === TabItemContent) {
      content = child;
    }
  });
  return [header, content];
}

export function TabItem(props) {
  return <></>;
}
