import React from 'react';
import itemStyles from './Item.module.css';

export function Item(props) {
  return (
    <div
      onClick={() => !props.selected && props.onClickHandler?.(props.value)}
      className={[itemStyles['dropdown-item'], props.selected ? itemStyles['dropdown-item--selected'] : ''].join(' ')}
    >
      {props.children}
    </div>
  );
}
