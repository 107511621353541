import React from "react";
import inputStyles from "./Input.module.css";

export const Input = React.forwardRef(
  ({ label, name, error, maxlength = 15, type="text" }, ref) => {
    return (
      <input
        className={[
          inputStyles.Input,
          error ? inputStyles.Input__error : "",
        ].join(" ")}
        placeholder={label}
        name={name}
        ref={ref}
        maxLength={maxlength}
        type={type}
      />
    );
  }
);
