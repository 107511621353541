import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import buyNowStyles from "./BuyNow.module.css";

export function BuyNow() {
  const { t } = useTranslation();
  return (
    <Link to="/contact" className={buyNowStyles.linkButton}>
      <button className={buyNowStyles.button}>
        <span>{t("buttons:buyNow")}</span>
      </button>
    </Link>
  );
}
