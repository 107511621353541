import React from 'react';
import { SectionHeader } from '../../SectionHeader/SectionHeader';
import { Tabs } from '../../Tabs/Tabs';

import mapIconSVG from '../../../assets/img/icons/map.svg';
import mapIconSelectedSVG from '../../../assets/img/icons/map.selected.svg';
import alertIconSVG from '../../../assets/img/icons/bell.svg';
import alertIconSelectedSVG from '../../../assets/img/icons/bell.selected.svg';
import clockIconSVG from '../../../assets/img/icons/clock.svg';
import clockIconSelectedSVG from '../../../assets/img/icons/clock.selected.svg';
import clockTurnIconSVG from '../../../assets/img/icons/clock-turn.svg';
import clockTurnIconSelectedSVG from '../../../assets/img/icons/clock-turn.selected.svg';
import peopleIconSVG from '../../../assets/img/icons/people.svg';
import peopleIconSelectedSVG from '../../../assets/img/icons/people.selected.svg';
import { useTranslation } from 'react-i18next';
import { SelectableItem } from '../../SelectableItem/SelectableItem';
import { TabContent } from '../../TabContent/TabContent';
import background from '../../../assets/img/product-background.jpg';
import productStyles from './Product.module.css';

import mapImage from '../../../assets/img/producto/map.png';
import alertImage from '../../../assets/img/producto/alert.png';
import employeeImage from '../../../assets/img/producto/employee.png';
import historyImage from '../../../assets/img/producto/history.png';
import timeMachineImage from '../../../assets/img/producto/timemachine.png';

export function Product(props) {
  const [t] = useTranslation();
  return (
    <section
      className={[productStyles.back, productStyles.product].join(' ')}
      id='product'
    >
      <SectionHeader product title='section:product:title' subtitle='section:product:subtitle'></SectionHeader>
      <div>
        <Tabs>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='blue'
                  selected={selected}
                  onSelectedIcon={mapIconSelectedSVG}
                  icon={mapIconSVG}
                  label={t('section:product:tabs:maps:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={mapIconSelectedSVG}
                title={t('section:product:tabs:maps:title')}
                text={t('section:product:tabs:maps:content')}
                img={mapImage}
                colorStyle='blue'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='blue'
                  selected={selected}
                  onSelectedIcon={clockIconSelectedSVG}
                  icon={clockIconSVG}
                  label={t('section:product:tabs:history:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={clockIconSelectedSVG}
                title={t('section:product:tabs:history:title')}
                text={t('section:product:tabs:history:content')}
                img={historyImage}
                colorStyle='blue'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='blue'
                  selected={selected}
                  onSelectedIcon={peopleIconSelectedSVG}
                  icon={peopleIconSVG}
                  label={t('section:product:tabs:employees:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={peopleIconSelectedSVG}
                title={t('section:product:tabs:employees:title')}
                text={t('section:product:tabs:employees:content')}
                img={employeeImage}
                colorStyle='blue'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='blue'
                  selected={selected}
                  onSelectedIcon={alertIconSelectedSVG}
                  icon={alertIconSVG}
                  label={t('section:product:tabs:alert:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={alertIconSelectedSVG}
                title={t('section:product:tabs:alert:title')}
                text={t('section:product:tabs:alert:content')}
                img={alertImage}
                colorStyle='blue'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
          <Tabs.TabItem>
            <Tabs.TabItemHeader>
              {(selected) => (
                <SelectableItem
                  item-style='blue'
                  selected={selected}
                  onSelectedIcon={clockTurnIconSelectedSVG}
                  icon={clockTurnIconSVG}
                  label={t('section:product:tabs:timemachine:title')}
                />
              )}
            </Tabs.TabItemHeader>
            <Tabs.TabItemContent>
              <TabContent
                icon={clockTurnIconSelectedSVG}
                title={t('section:product:tabs:timemachine:title')}
                text={t('section:product:tabs:timemachine:content')}
                img={timeMachineImage}
                colorStyle='blue'
              />
            </Tabs.TabItemContent>
          </Tabs.TabItem>
        </Tabs>
      </div>
    </section>
  );
}
