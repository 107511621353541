import React from 'react';
import tabContentStyles from './TabContent.module.css';
import { TabContentHeader } from './TabContentHeader/TabContentHeader';

export function TabContent(props) {
  return (
    <div className={tabContentStyles['tab-content']}>
      <div className={tabContentStyles['tab-content__image']}>
      <img src={props.img} alt='' />
      </div>
      {props.img ? (
        <div
          className={[
            tabContentStyles['tab-content__content'],
            props.colorStyle ? tabContentStyles[`tab-content__content--${props.colorStyle}`] : '',
          ].join(' ')}
        >
          <TabContentHeader icon={props.icon} label={props.title} content-style={props.colorStyle} />
          <p>{props.text}</p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
