import React, { useState } from 'react';
import { useScrollPosition } from '../../hooks/useScrollPosition.hook';
import { Links } from '../Links/Links';
import { Logo } from '../Logo/Logo';
import { Menu } from '../Menu/Menu';
import navbarStyles from './NavBar.module.css';
import classnames from 'classnames';

const DEFAULT_DISTANCE = 600;

export function NavBar(props) {
  const pricing = 'pricing' in props;
  const isFixed = 'fixed' in props;
  const useWhiteStyle = 'white' in props;
  const avoidMenu = 'no-menu' in props;
  const useHashNav = 'only-hash-nav' in props;
  const showFrom = isFixed && props.showFrom ? props.showFrom ?? DEFAULT_DISTANCE : 0;
  const [showing, setShowing] = useState(props.initialState ?? false);

  const onScroll = ({ currPos }) => {
    if (isFixed) {
      if (currPos.y > showFrom) {
        setShowing(true);
      } else if (currPos.y < showFrom) {
        setShowing(false);
      }
    }
  };

  useScrollPosition(onScroll, [], null, true);

  return (
    <header
      className={classnames(navbarStyles.navbar, {
        [navbarStyles['navbar--white']]: useWhiteStyle,
        [navbarStyles['navbar__fixed']]: isFixed,
        [navbarStyles['navbar__fixed--showing']]: showing,
      })}
    >
      <Logo height='45px' logoStyle={isFixed || useWhiteStyle ? 'black' : 'default'} />
      
        <Menu useBlack={isFixed || useWhiteStyle} only-hash-nav={useHashNav} />
      
     
      <div className={navbarStyles['navbar__special']}>
        <Links.CustomerLogin />
        <Links.GetPricing />    
      </div>
      
    </header>
  );
}
